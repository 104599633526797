/*
 * author kcz
 * date 2019-11-20
 */
import KFormBuild from './index.vue'

KFormBuild.install = function (Vue) {
  Vue.component(KFormBuild.name, KFormBuild)
}

export default KFormBuild
