/*
 * @Description: 增加antd ui 组件
 * @Author: kcz
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: kcz
 * @LastEditTime: 2020-07-07 23:12:33
 */

// 导入ant组件
import './core/components_use'
import * as all from './mini'

export default all.default
export const KFormBuild = all.KFormBuild
export const KFormItem = all.KFormItem
