/*
 * @Description: 组件输出文件,不打包antd ui组件
 * @Author: kcz
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: kcz
 * @LastEditTime: 2020-07-07 22:22:54
 */

// 解决Chrome控制台non-passive event listener输出问题
// import 'default-passive-events'
// 导入样式
import '../styles/form-design.less'

// 导出本地iconfont
import '../static/icons/iconfont'

// 导入单个组件
import KFormBuild from './KFormBuild/index'
import KFormItem from './KFormItem/index'
const components = [KFormBuild, KFormItem]

const install = function (Vue) {
  // use ant组件
  if (install.installed) return
  install.installed = true

  components.map((component) => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

// 这里可以用es6的解构语法导入组件
export { KFormBuild, KFormItem }

// 这里默认导入全部组件
export default {
  install,
}
