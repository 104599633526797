<template>
  <a-form-item
    :class="
      record.options.isCustomLabelCol ? 'custom-label-col' + record.model : ''
    "
    :label-col="formConfig.layout === 'horizontal' ? formConfig.labelCol : {}"
    :wrapper-col="
      formConfig.layout === 'horizontal' ? formConfig.wrapperCol : {}
    "
  >
    <!-- :label="record.label" 改为支持样式 -->
    <template slot="label" v-if="record.labelHTML || record.label">
      <span
        class="ant-form-item-label-slot"
        v-html="record.labelHTML || record.label"
      ></span>
    </template>
    <component
      :record="record"
      :formConfig="formConfig"
      :style="`width:${record.options.width}`"
      @change="handleChange"
      :disabled="disabled"
      :dynamicData="dynamicData"
      :height="
        typeof record.options.height !== 'undefined'
          ? record.options.height
          : ''
      "
      v-decorator="[
        record.model,
        {
          initialValue: record.options.defaultValue,
          rules: record.rules,
        },
      ]"
      :is="customComponent"
    ></component>
  </a-form-item>
</template>
<script>
export default {
  name: 'customComponent',
  props: ['record', 'formConfig', 'disabled', 'dynamicData'],
  computed: {
    customComponent() {
      // 计算需要显示的组件
      const customComponentList = {}
      if (window.$customComponentList) {
        // 将数组映射成json
        window.$customComponentList.forEach((item) => {
          customComponentList[item.type] = item.component
        })
      }
      return customComponentList[this.record.type]
    },
  },
  watch: {
    'record.options.isCustomLabelCol'(val) {
      if (val !== undefined) {
        this.setFormItemStyle()
      }
    },
    'record.options.labelCol.span'(val) {
      if (val !== undefined) {
        this.setFormItemStyle()
      }
    },
  },
  methods: {
    handleChange(value, key) {
      this.$emit('change', value, key)
    },
    async setFormItemStyle() {
      try {
        if (!this.record.options.labelCol) {
          return false
        }
        // var width = (eval(this.record.options.labelCol.span) / 24) * 100
        var width = (this.record.options.labelCol.span / 24) * 100
        this.$nextTick(() => {
          if (this.record.options.labelCol) {
            var label = document.querySelectorAll(
              '.custom-label-col' + this.record.model + ' .ant-form-item-label'
            )
            var input = document.querySelectorAll(
              '.custom-label-col' +
                this.record.model +
                ' .ant-form-item-control-wrapper'
            )
            if (typeof width !== 'number') {
              return false
            }
            if (label) {
              label.forEach((item) => {
                item.style.width = width + '%'
              })
            }
            if (input) {
              input.forEach((item) => {
                item.style.width = 100 - width + '%'
              })
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
<style>
.ant-form-item-label-slot {
  white-space: pre-line;
}
.kfd-table input,
.kfd-table textarea,
.kfd-table .ant-select-selection,
.kfd-table .ant-input-number {
  border: none !important;
}
.form-warp .ant-form-item {
  margin-bottom: 20px;
}
/*.form-warp-help .ant-form-item:not(.ant-form-item-with-help){
    margin-bottom: 24px;
  } */
.form-warp .ant-form-explain {
  position: absolute;
  z-index: 9999;
}
</style>
