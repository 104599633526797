<template>
  <div style="text-align: right;">
    <a-button v-print="id_" icon="printer"></a-button>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      printObj: {
        id: 'printWarp',
        popTitle: '11111'
      },
      id_:'#printContent'
    }
  },
  mounted() {
  },
  destroyed() {

  },
  methods: {
    
  }
}
</script>
