<!--
 * @Description: 传入record数据，通过判断record.type，来渲染对应的组件
 * @Author: kcz
 * @Date: 2020-01-02 22:41:48
 * @LastEditors: kcz
 * @LastEditTime: 2020-07-09 22:24:34
 -->
<template>
  <div>
    <a-form-item
      v-if="
        !(record.options.hidden === true) &&
        [
          'input',
          'textarea',
          'date',
          'time',
          'number',
          'radio',
          'checkbox',
          'select',
          'rate',
          'switch',
          'slider',
          'uploadImg',
          'uploadFile',
          'cascader',
          'treeSelect',
        ].includes(record.type)
      "
      :class="
        record.options.isCustomLabelCol ? 'custom-label-col' + record.model : ''
      "
      :label-col="formConfig.layout === 'horizontal' ? formConfig.labelCol : {}"
      :wrapper-col="
        formConfig.layout === 'horizontal' ? formConfig.wrapperCol : {}
      "
    >
      <template slot="label" v-if="record.labelHTML || record.label">
        <span
          class="ant-form-item-label-slot aaa"
          v-html="record.labelHTML || record.label"
        ></span>
      </template>
      <!-- 单行文本 -->
      <a-input
        :style="`width:${record.options.width}`"
        v-if="record.type === 'input'"
        :disabled="disabled || record.options.disabled"
        :placeholder="record.options.placeholder"
        :type="record.options.type"
        :allowClear="record.options.clearable"
        :maxLength="record.options.maxLength"
        @change="handleChange($event.target.value, record.model)"
        v-decorator="[
          record.model, // input 的 name
          {
            initialValue: record.options.defaultValue, // 默认值
            rules: record.rules, // 验证规则
          },
        ]"
      />
      <!-- 多行文本 -->
      <a-textarea
        :style="`width:${record.options.width}`"
        v-else-if="record.type === 'textarea'"
        :autoSize="{
          minRows: record.options.minRows,
          maxRows: record.options.maxRows,
        }"
        :disabled="disabled || record.options.disabled"
        :placeholder="record.options.placeholder"
        :allowClear="record.options.clearable"
        :maxLength="record.options.maxLength"
        :rows="4"
        @change="handleChange($event.target.value, record.model)"
        v-decorator="[
          record.model, // input 的 name
          {
            initialValue: record.options.defaultValue, // 默认值
            rules: record.rules, // 验证规则
          },
        ]"
      />

      <!-- 日期选择 -->
      <KDatePicker
        v-else-if="record.type === 'date'"
        :record="record"
        :parentDisabled="disabled"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model, // input 的 name
          {
            initialValue: record.options.range
              ? record.options.rangeDefaultValue
              : record.options.defaultValue, // 默认值
            rules: record.rules, // 验证规则
          },
        ]"
      />
      <!-- 时间选择 -->
      <KTimePicker
        v-else-if="record.type === 'time'"
        :record="record"
        :parentDisabled="disabled"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model, // input 的 name
          {
            initialValue: record.options.defaultValue, // 默认值
            rules: record.rules, // 验证规则
          },
        ]"
      />
      <!-- 数字输入框 -->
      <a-input-number
        v-else-if="record.type === 'number'"
        :style="`width:${record.options.width}`"
        :min="
          record.options.min || record.options.min === 0
            ? record.options.min
            : -Infinity
        "
        :max="
          record.options.max || record.options.max === 0
            ? record.options.max
            : Infinity
        "
        :disabled="disabled || record.options.disabled"
        :step="record.options.step"
        :precision="
          record.options.precision > 50 ||
          (!record.options.precision && record.options.precision !== 0)
            ? null
            : record.options.precision
        "
        :placeholder="record.options.placeholder"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 单选框 -->
      <a-radio-group
        v-else-if="record.type === 'radio'"
        :options="getOptions(record) || []"
        :disabled="disabled || record.options.disabled"
        :placeholder="record.options.placeholder"
        @change="handleChange($event.target.value, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 多选框 -->
      <!-- <a-checkbox-group
      v-else-if="record.type === 'checkbox'"
      :options="
        record.options.dynamic === 'static'
          ? record.options.options
          : dynamicData[record.options.dynamicKey]
          ? dynamicData[record.options.dynamicKey]
          : []
      "
      :disabled="disabled || record.options.disabled"
      :placeholder="record.options.placeholder"
      @change="handleChange($event, record.model)"
      v-decorator="[
        record.model,
        {
          initialValue: record.options.defaultValue,
          rules: record.rules
        }
      ]"
    /> -->
      <a-checkbox-group
        v-else-if="record.type === 'checkbox'"
        :options="getOptions(record) || []"
        :disabled="disabled || record.options.disabled"
        :placeholder="record.options.placeholder"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- <div v-else-if="record.type === 'checkbox' && record.options.dynamic === 'distal'">仅在预览时可用</div> -->
      <!-- 评分 -->
      <a-rate
        v-else-if="record.type === 'rate'"
        :count="record.options.max"
        :disabled="disabled || record.options.disabled"
        :placeholder="record.options.placeholder"
        :allowHalf="record.options.allowHalf"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 下拉选框 -->
      <!-- @search="handleSearch"
      :filter-option="false" -->
      <a-select
        :style="`width:${record.options.width}`"
        v-else-if="record.type === 'select'"
        :placeholder="record.options.placeholder"
        :showSearch="record.options.showSearch"
        :filter-option="filterOption"
        :options="getOptions(record)"
        :disabled="disabled || record.options.disabled"
        :allowClear="record.options.clearable"
        :mode="record.options.multiple ? 'multiple' : ''"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 开关 -->
      <a-switch
        v-else-if="record.type === 'switch'"
        :disabled="disabled || record.options.disabled"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            valuePropName: 'checked',
            rules: record.rules,
          },
        ]"
      />
      <!-- 滑块 -->
      <div
        v-else-if="record.type === 'slider'"
        :style="`width:${record.options.width}`"
        class="slider-box"
      >
        <div class="slider">
          <a-slider
            :disabled="disabled || record.options.disabled"
            :min="record.options.min"
            :max="record.options.max"
            :step="record.options.step"
            @change="handleChange($event, record.model)"
            v-decorator="[
              record.model,
              {
                initialValue: record.options.defaultValue,
                rules: record.rules,
              },
            ]"
          />
        </div>
        <div class="number" v-if="record.options.showInput">
          <a-input-number
            style="width: 100%"
            :disabled="disabled || record.options.disabled"
            :min="record.options.min"
            :max="record.options.max"
            :step="record.options.step"
            @change="handleChange($event, record.model)"
            v-decorator="[
              record.model,
              {
                initialValue: record.options.defaultValue,
              },
            ]"
          />
        </div>
      </div>
      <!-- 上传图片 -->
      <UploadImg
        v-else-if="record.type === 'uploadImg'"
        :style="`width:${record.options.width}`"
        :parentDisabled="disabled"
        :record="record"
        :config="config"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 上传文件 -->
      <UploadFile
        v-else-if="record.type === 'uploadFile'"
        :style="`width:${record.options.width}`"
        :record="record"
        :config="config"
        :dynamicData="dynamicData"
        :parentDisabled="disabled"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 树选择器 -->
      <a-tree-select
        v-else-if="record.type === 'treeSelect'"
        :style="`width:${record.options.width}`"
        :placeholder="record.options.placeholder"
        :multiple="record.options.multiple"
        :showSearch="record.options.showSearch"
        :treeCheckable="record.options.treeCheckable"
        :treeData="
          !record.options.dynamic
            ? record.options.options
            : dynamicData[record.options.dynamicKey]
            ? dynamicData[record.options.dynamicKey]
            : []
        "
        :disabled="disabled || record.options.disabled"
        :allowClear="record.options.clearable"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 级联选择器 -->
      <a-cascader
        v-else-if="record.type === 'cascader'"
        :style="`width:${record.options.width}`"
        :placeholder="record.options.placeholder"
        :showSearch="record.options.showSearch"
        :options="getOptions(record) || []"
        :disabled="disabled || record.options.disabled"
        :allowClear="record.options.clearable"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
    </a-form-item>
    <!-- 可隐藏label -->
    <a-form-item
      v-else-if="record.type === 'batch' || record.type === 'editor'"
      :label="!record.options.showLabel ? '' : record.label"
      :label-col="
        formConfig.layout === 'horizontal' && record.options.showLabel
          ? formConfig.labelCol
          : {}
      "
      :wrapper-col="
        formConfig.layout === 'horizontal' && record.options.showLabel
          ? formConfig.wrapperCol
          : {}
      "
    >
      <!-- 动态表格 -->
      <KBatch
        v-if="record.type === 'batch'"
        ref="KBatch"
        :style="`width:${record.options.width}`"
        :record="record"
        :config="config"
        :parentDisabled="disabled"
        :dynamicData="dynamicData"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
      <!-- 富文本编辑器 -->
      <KEditor
        v-else
        ref="KEditor"
        :style="`width:${record.options.width}`"
        :record="record"
        :parentDisabled="disabled"
        :dynamicData="dynamicData"
        @change="handleChange($event, record.model)"
        v-decorator="[
          record.model,
          {
            initialValue: record.options.defaultValue,
            rules: record.rules,
          },
        ]"
      />
    </a-form-item>
    <!-- button按钮 -->
    <a-form-item
      v-else-if="record.type === 'button'"
      :wrapper-col="
        formConfig.layout === 'horizontal'
          ? { ...formConfig.wrapperCol, offset: formConfig.labelCol.span }
          : {}
      "
    >
      <a-button
        :disabled="disabled || record.options.disabled"
        @click="
          record.options.handle === 'submit'
            ? false
            : record.options.handle === 'reset'
            ? $emit('handleReset')
            : dynamicData[record.options.dynamicFun]
            ? dynamicData[record.options.dynamicFun]()
            : false
        "
        :type="record.options.type"
        :html-type="record.options.handle === 'submit' ? 'submit' : undefined"
        v-text="record.label"
      ></a-button>
    </a-form-item>
    <!-- alert提示 -->
    <a-form-item v-else-if="record.type === 'alert'">
      <a-alert
        :message="record.label"
        :description="record.options.description"
        :type="record.options.type"
        :showIcon="record.options.showIcon"
        :closable="record.options.closable"
        :banner="record.options.banner"
      />
    </a-form-item>
    <!-- 文本 -->
    <a-form-item v-else-if="record.type === 'text'">
      <div :style="{ textAlign: record.options.textAlign }">
        <label
          :class="{ 'ant-form-item-required': record.options.showRequiredMark }"
          v-text="record.label"
        ></label>
      </div>
    </a-form-item>
    <!-- html -->
    <div
      v-else-if="record.type === 'html'"
      v-html="record.options.defaultValue"
    ></div>

    <div class="kfd-divider" v-else>
      <!-- 分割线 -->
      <a-divider
        v-if="
          record.type === 'divider' &&
          record.label !== '' &&
          record.options.orientation
        "
        :orientation="record.options.orientation"
        >{{ record.label }}</a-divider
      >
      <a-divider v-else-if="record.type === 'divider' && record.label !== ''">{{
        record.label
      }}</a-divider>
      <a-divider v-else-if="record.type === 'divider' && record.label === ''" />
    </div>
    <!-- 自定义组件 -->
    <customComponent
      v-show="customList.includes(record.type) && !record.options.hidden"
      :record="record"
      :disabled="disabled || record.options.disabled"
      :dynamicData="dynamicData"
      @change="handleChange($event, record.model)"
      :formConfig="formConfig"
    />
  </div>
</template>
<script>
/*
 * author kcz
 * date 2019-11-20
 */
// import moment from "moment";
import customComponent from './customComponent'

import KBatch from '../KBatch'
import KEditor from '../KEditor'
import UploadFile from '../UploadFile'
import UploadImg from '../UploadImg'
import KDatePicker from '../KDatePicker'
import KTimePicker from '../KTimePicker'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'KFormItem',
  props: {
    // 表单数组
    record: {
      type: Object,
      required: true,
    },
    // form-item 宽度配置
    formConfig: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    dynamicData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    KBatch,
    KEditor,
    UploadImg,
    UploadFile,
    KDatePicker,
    KTimePicker,
    customComponent,
  },
  data() {
    return {
      defaultOptions: [],
    }
  },
  computed: {
    customList() {
      if (window.$customComponentList) {
        return window.$customComponentList.map((item) => item.type)
      } else {
        return []
      }
    },
  },
  watch: {
    'record.options.isCustomLabelCol'(val) {
      if (val !== undefined) {
        this.setFormItemStyle()
      }
    },
    'record.options.labelCol.span'(val) {
      if (val !== undefined) {
        this.setFormItemStyle()
      }
    },
  },
  created() {
    console.log('record', this.record)
  },
  methods: {
    moment,
    ...mapGetters(['authInfo', 'userInfo']),
    validationSubform() {
      // 验证动态表格
      if (!this.$refs.KBatch) return true
      return this.$refs.KBatch.validationSubform()
    },
    handleChange(value, key) {
      console.log(value, key)
      // change事件
      this.$emit('change', value, key)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getOptions(record) {
      if (record.options.dynamic === 'static') {
        return record.options.options
      } else if (record.options.dynamic === 'distal') {
        return record.options.distal.tableName
          ? this.dynamicData[record.options.distal.tableName]
          : []
      } else if (record.options.dynamic === 'dict') {
        return record.options.dynamicKey
          ? this.dynamicData[record.options.dynamicKey]
          : []
      } else {
        return []
      }
    },
    async setFormItemStyle() {
      try {
        if (!this.record.options.labelCol) {
          return false
        }
        // var width = (eval(this.record.options.labelCol.span) / 24) * 100
        var width = (this.record.options.labelCol.span / 24) * 100
        this.$nextTick(() => {
          if (this.record.options.labelCol) {
            var label = document.querySelectorAll(
              '.custom-label-col' + this.record.model + ' .ant-form-item-label'
            )
            var input = document.querySelectorAll(
              '.custom-label-col' +
                this.record.model +
                ' .ant-form-item-control-wrapper'
            )
            if (typeof width !== 'number') {
              return false
            }
            if (label) {
              label.forEach((item) => {
                item.style.width = width + '%'
              })
            }
            if (input) {
              input.forEach((item) => {
                item.style.width = 100 - width + '%'
              })
            }

            if (this.record.model == 'input_enterpriseName') {
              this.$parent.$parent.form.setFieldsValue({
                input_enterpriseName: this.authInfo().enterpriseName,
              })
            }
            if (this.record.model == 'input_currentDate') {
              this.$parent.$parent.form.setFieldsValue({
                input_currentDate: this.moment().format('YYYY-MM-DD'),
              })
            }

            if (this.record.model == 'input_currentName') {
              this.$parent.$parent.form.setFieldsValue({
                input_currentName: this.userInfo().realname
                  ? this.userInfo().realname
                  : this.userInfo().username,
              })
            }
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
  mounted() {
    this.setFormItemStyle()
  },
}
</script>
<style>
.kfd-table input,
.kfd-table textarea,
.kfd-table .ant-select-selection,
.kfd-table .ant-input-number {
  border: none !important;
}
.ant-form-item-label-slot {
  white-space: pre-line;
}
.form-warp .ant-form-item {
  margin-bottom: 20px;
}
/* .form-warp-help .ant-form-item:not(.ant-form-item-with-help){
    margin-bottom: 24px;
  } */
.form-warp .ant-form-explain {
  position: absolute;
  z-index: 9999;
}
</style>
<style lang="less" scoped>
.slider-box {
  display: flex;
  > .slider {
    flex: 1;
    margin-right: 16px;
  }
  > .number {
    width: 70px;
  }
}
.kfd-divider .ant-divider-horizontal {
  margin: 8px 0px;
}
.grid-col .ant-form-item {
  padding-right: 8px;
}
</style>
