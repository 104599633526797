import { axios } from '@/utils/request'

export function checkDuplicateKey(parameter) { // 校验字段重复
  return axios({
    url: '/activiti/models/checkKey',
    method: 'get',
    params: parameter
  })
}

export function getFormByModelId(parameter) { // 根据modelId获取kdf表单设计器json
  return axios({
    url: '/activiti/form/getFormByModelId',
    method: 'get',
    params: parameter
  })
}

export function getFormByProcessDefinitionId(parameter) { // 根据processDefinitionId查询部署表单
  return axios({
    url: '/activiti/form/getFormByProcessDefinitionId',
    method: 'get',
    params: parameter
  })
}

export function getFormDataById(parameter) { // 根据ID查询流程表单数据
  return axios({
    url: '/activiti/formData/getFormDataById',
    method: 'get',
    params: parameter
  })
}

export function getFormDataByProcessInstanceId(parameter) { // 根据processInstanceId查询流程表单数据
  return axios({
    url: '/activiti/formData/getFormDataByProcessInstanceId',
    method: 'get',
    params: parameter
  })
}

export function getHistoryFormDataByProcessInstanceId(parameter) { // 根据processInstanceId查询流程历史表单数据
  return axios({
    url: 'activiti/hiFormData/getFormDataByProcessInstanceId',
    method: 'get',
    params: parameter
  })
}

export function getFlowByModelId(parameter) { // 根据modelId查询流程数据
  return axios({
    url: '/activiti/flow/getFlowByModelId',
    method: 'get',
    params: parameter
  })
}

export function deployment(parameter) { // 流程发布
  return axios({
    url: '/activiti/models/deployment',
    method: 'get',
    params: parameter
  })
}

export function saveFlow(parameter) { // 保存流程
  return axios({
    url: '/activiti/flow/saveFlow',
    method: 'post',
    data: parameter
  })
}

export function saveOrUpdateForm(parameter) { // 保存表单设计器
  return axios({
    url: '/activiti/form/saveOrUpdateForm',
    method: 'post',
    data: parameter
  })
}

export function saveOrUpdate(parameter) { // 保存设计表单数据
  return axios({
    url: '/activiti/formData/saveOrUpdate',
    method: 'post',
    data: parameter
  })
}

// export function deleteBatch(parameter) { // 保存设计表单数据
//   return axios({
//     url: '/activiti/formData/deleteBatch',
//     method: 'post',
//     data: parameter
//   })
// }

export function getNodeList(parameter) { // 根据modelId获取流程节点
  return axios({
    url: '/activiti/node/getNodeListByModelId',
    method: 'get',
    params: parameter
  })
}

export function saveNode(parameter) { // 流程节点编辑保存
  return axios({
    url: '/activiti/node',
    method: 'post',
    data: parameter
  })
}

export function getActKAppendForm(parameter) { // 查询流程节点附加表单
  return axios({
    url: '/activiti/appendForm/getActKAppendForm',
    method: 'get',
    params: parameter
  })
}

export function getActKAppendFormDeployment(parameter) { // 查询流程节点附加表单
  return axios({
    url: '/activiti/appendForm/getActKAppendFormDeployment',
    method: 'get',
    params: parameter
  })
}

export function saveNodeForm(parameter) { // 保存/修改流程节点附加表单
  return axios({
    url: '/activiti/appendForm/saveOrUpdate',
    method: 'post',
    data: parameter
  })
}

export function getActKAppendFormData(parameter) { // 查询流程节点附加表单数据
  return axios({
    url: '/activiti/appendFormData/getActKAppendFormData',
    method: 'get',
    params: parameter
  })
}

export function saveNodeFormData(parameter) { // 保存/修改流程节点附加表单数据
  return axios({
    url: '/activiti/appendFormData/saveOrUpdate',
    method: 'post',
    data: parameter
  })
}

export function startInstance(parameter) { // 提交流程
  return axios({
    url: '/activiti/instance/startInstance',
    method: 'get',
    params: parameter
  })
}

export function getReport(parameter) { // 根据online表单tableId获取流程数据
  return axios({
    url: '/activiti/report/getReport',
    method: 'get',
    params: parameter
  })
}

export function getFlowinfo(parameter) { // 根据online表单tableId formDataId获取流程数据
  return axios({
    url: '/activiti/report/getFlowinfo',
    method: 'get',
    params: parameter
  })
}
