<template>
  <a-config-provider :locale="locale">
    <a-form
      v-if="
        typeof value.list !== 'undefined' && typeof value.config !== 'undefined'
      "
      class="k-form-build-9136076486841527 form-warp"
      :layout="value.config.layout"
      :hideRequiredMark="value.config.hideRequiredMark"
      :form="form"
      @submit="handleSubmit"
      :style="value.config.customStyle"
    >
      <print v-if="value.config.isPrint" :value="value"></print>
      <div id="printContent">
        <buildBlocks
          ref="buildBlocks"
          @handleReset="reset"
          v-for="(record, index) in value.list"
          :record="record"
          :dynamicData="
            Object.keys(dynamicDataAjax).length > 0
              ? dynamicDataAjax
              : dynamicData
          "
          :config="config"
          :disabled="disabled"
          :formConfig="value.config"
          :key="index"
          @change="handleChange"
        />
      </div>
    </a-form>
  </a-config-provider>
</template>
<script>
/*
 * author kcz
 * date 2019-11-20
 * description 将json数据构建成表单
 */
import buildBlocks from './buildBlocks'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import Print from '../../custom/Print/index'
import { getFlowFormDictData } from '@/api/dict'
// import moment from "moment";
export default {
  name: 'KFormBuild',
  data() {
    return {
      locale: zhCN,
      form: this.$form.createForm(this),
      dynamicDataCode: {},
      dynamicDataCodeParam: [],
      dynamicDataAjax: {},
    }
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    dynamicData: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outputString: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    buildBlocks,
    Print,
  },
  methods: {
    // moment,
    handleSubmit(e) {
      // 提交按钮触发，并触发submit函数，返回getData函数
      e.preventDefault()
      this.$emit('submit', this.getData)
    },
    reset() {
      // 重置表单
      this.form.resetFields()
    },
    getData() {
      // 提交函数，提供父级组件调用
      return new Promise((resolve, reject) => {
        try {
          // const form = document.querySelectorAll('.form-warp')
          // form.forEach(item => { // 添加form-warp-help类 作为css判断是否检验
          //   item.classList.add('form-warp-help')
          // })
          this.form.validateFields((err, values) => {
            if (err) {
              reject(err)
            }
            this.$refs.buildBlocks.forEach((item) => {
              if (!item.validationSubform()) {
                reject(err)
              }
            })
            if (this.outputString) {
              // 需要所有value转成字符串
              for (const key in values) {
                const type = typeof values[key]
                if (type === 'string' || type === 'undefined') {
                  continue
                } else if (type === 'object') {
                  values[key] = `k-form-design#${type}#${JSON.stringify(
                    values[key]
                  )}`
                } else {
                  values[key] = `k-form-design#${type}#${String(values[key])}`
                }
              }
              resolve(values)
            } else {
              resolve(values)
            }
          })
        } catch (err) {
          reject(err)
        }
      })
    },
    setData(json) {
      return new Promise((resolve, reject) => {
        try {
          if (this.outputString) {
            // 将非string数据还原
            for (const key in json) {
              if (!json[key].startsWith('k-form-design#')) {
                continue
              }
              const array = json[key].split('#')
              if (array[1] === 'object') {
                json[key] = JSON.parse(array[2])
              } else if (array[1] === 'number') {
                json[key] = Number(array[2])
              } else if (array[1] === 'boolean') {
                json[key] = Boolean(array[2])
              }
            }
            this.form.setFieldsValue(json)
          } else {
            this.form.setFieldsValue(json)
          }
        } catch (err) {
          reject(err)
        }
      })
    },

    // 批量设置某个option的值
    setOptions(fields, optionName, value) {
      fields = new Set(fields)
      // 递归遍历控件树
      const traverse = (array) => {
        array.forEach((element) => {
          if (fields.has(element.model)) {
            element.options[optionName] = value
          }
          if (element.type === 'grid') {
            // 栅格布局
            element.columns.forEach((item) => {
              traverse(item.list)
            })
          } else if (element.type === 'card') {
            // 卡片布局
            traverse(element.list)
          } else if (element.type === 'batch') {
            // 动态表格
            traverse(element.list)
          }
          if (element.type === 'table') {
            // 表格布局
            element.trs.forEach((item) => {
              item.tds.forEach((val) => {
                traverse(val.list)
              })
            })
          }
        })
      }
      traverse(this.value.list)
    },
    // 隐藏表单字段
    hide(fields) {
      this.setOptions(fields, 'hidden', true)
    },
    // 显示表单字段
    show(fields) {
      this.setOptions(fields, 'hidden', false)
    },
    // 禁用表单字段
    disable(fields) {
      this.setOptions(fields, 'disabled', true)
    },
    // 启用表单字段
    enable(fields) {
      this.setOptions(fields, 'disabled', false)
    },
    handleChange(value, key) {
      // 触发change事件
      this.$emit('change', value, key)
    },
    getDynamicDataCode(list) {
      // 获取所有需要查询的字典
      const that = this
      list.forEach(function (item, i) {
        console.log(i)
        const dynamic = item.options.dynamic
        const dynamicKey = item.options.dynamicKey
        if (
          item.options &&
          (dynamic === 'distal' || dynamic === 'dict') &&
          !that.dynamicDataCode[dynamicKey]
        ) {
          if (dynamic === 'distal') {
            that.dynamicDataCodeParam.push({
              type: 'table',
              value: item.options.distal.value,
              label: item.options.distal.label,
              code: item.options.distal.tableName,
            })
          } else {
            that.dynamicDataCodeParam.push({
              type: 'dict',
              code: dynamicKey,
            })
          }
          that.dynamicDataCode[dynamicKey] = true
        }
        if (item.list) {
          that.getDynamicDataCode(item.list)
        } else if (item.columns) {
          item.columns.forEach((column) => {
            column.list && that.getDynamicDataCode(column.list)
          })
        }
      })
    },
    getFormList(data) {
      // 根据表单设计器json获取组件（不包含布局容器）
      let formList = []
      data.forEach((item) => {
        if (item.model && item.type !== 'batch') {
          formList.push(item)
        } else if (item.type === 'card') {
          formList = formList.concat(this.getFormList(item.list))
        } else if (item.type === 'grid') {
          item.columns.forEach((element) => {
            formList = formList.concat(this.getFormList(element.list))
          })
        } else if (item.type === 'table') {
          item.trs.forEach((tr) => {
            tr.tds.forEach((td) => {
              formList = formList.concat(this.getFormList(td.list))
            })
          })
        }
      })
      return formList
    },
    setDynamicDataAjax(datas) {
      const that = this
      Object.keys(datas).forEach((key) => {
        const list = []
        datas[key].forEach((item) => {
          list.push({
            label: item.text,
            value: item.value,
            title: item.value,
          })
        })
        that.$set(that.dynamicDataAjax, key, list)
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setData(this.defaultValue)
    })
    if (Object.keys(this.dynamicData).length === 0) {
      const formList = this.value.list && this.getFormList(this.value.list)
      formList && this.getDynamicDataCode(formList)
      getFlowFormDictData({
        jsonStr: JSON.stringify(this.dynamicDataCodeParam),
      }).then((res) => {
        if (res.success) {
          this.setDynamicDataAjax(res.result)
        } else {
          this.$message.warning(res.message)
        }
      })
    }
  },
}
</script>
